interface Request {
    method: 'GET' | 'POST' | string,
    name: string,
    params: {
        action: string,
        id: number | null,
        [index: string]: any
    }
}

declare global {
    const request: Request;
}

export default function route(request: Request): void {
    console.log(request, request.name, request.params.action);
    switch (request.name ?? '') {
        case 'Front:Home':
            switch (request.params.action) {
                case 'default':
                    import(
                        /* webpackChunkName: "front_home_default" */
                        './pages/front/home/default'
                        ).then(module => {
                        module.default();
                    });
                    break;
            }
            break;
        case 'Admin:ContentProvider':
            switch (request.params.action) {
                case 'edit':
                case 'create':
                    import(
                        /* webpackChunkName: "admin_contentProvider_edit" */
                        './pages/admin/contentProvider/edit'
                        ).then(module => {
                        module.default();
                    });
                    break;
            }
            break;
        case 'Admin:Reclamation':
            switch (request.params.action) {
                case 'edit':
                case 'create':
                    import(
                        /* webpackChunkName: "admin_reclamation_edit" */
                        './pages/admin/reclamation/edit'
                        ).then(module => {
                        module.default();
                    });
                    break;
            }
            break;
        case 'Admin:Number':
            switch (request.params.action) {
                case 'create':
                    import(
                        /* webpackChunkName: "admin_number_create" */
                        './pages/admin/number/create'
                        ).then(module => {
                        module.default();
                    });
                    break;
                case 'edit':
                    import(
                        /* webpackChunkName: "admin_number_edit" */
                        './pages/admin/number/edit'
                        ).then(module => {
                        module.default();
                    });
                    break;
            }
            break;
        case 'Admin:Reporting':
            switch (request.params.action) {
                case 'default':
                    import(
                        /* webpackChunkName: "admin_reporting_default" */
                        './pages/admin/reporting/default'
                        ).then(module => {
                        module.default();
                    });
                    break;
            }
            break;
        case 'Admin:User':
            switch (request.params.action) {
                case 'edit':
                case 'create':
                    import(
                        /* webpackChunkName: "admin_user_edit" */
                        './pages/admin/user/edit'
                        ).then(module => {
                        module.default();
                    });
                    break;
            }
            break;
    }
}