import axios, {AxiosResponse} from "axios";

export default async function initLanguagePicker() {
    let picker = document.getElementById('language-picker');
    if (!picker) {
        const response: AxiosResponse<string> = await axios.get('/language/select');
        const tmp = document.createElement('div');
        tmp.innerHTML = response.data;
        picker = tmp.firstElementChild as HTMLElement;
        document.querySelector('nav.navbar .navbar-collapse').appendChild(picker);
    }

    const links: NodeListOf<HTMLAnchorElement> = picker.querySelectorAll('.select-language');
    links.forEach(link => {
        const href = link.href;
        link.addEventListener('click', e => {
            e.preventDefault()
            axios.get(href)
                .then(() => {
                    window.location.reload();
                });
        });
    });
}