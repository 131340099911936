import {initTooltips} from './functions';
import axios from 'axios';
import * as bootstrap from "bootstrap";
import route from "./router";
import initDatePickers from "./datePickers";
import initLanguagePicker from "./components/language";

axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.addEventListener("load", () => {
    // Auto-format tel
    /*(document.querySelectorAll('input[type="tel"]') as NodeListOf<HTMLInputElement>).forEach(input => {
        if (input.classList.contains('not-format')) {
            return;
        }
        input.value = formatPhoneNumber(input.value);
        input.addEventListener("keydown", () => {
            input.value = formatPhoneNumber(input.value);
        });
        input.addEventListener("change", () => {
            input.value = formatPhoneNumber(input.value);
        });
    });*/

    initLanguagePicker();

    // Select description
    (document.querySelectorAll('.select-description') as NodeListOf<HTMLDivElement>).forEach(element => {
        if (!element.dataset.target) {
            // Missing target
            console.log('Missing target');
            return;
        }

        const target = document.querySelector(element.dataset.target) as HTMLSelectElement;
        console.log(target, target.value);
        if (!target) {
            // Invalid target
            console.log('Invalid target');
            return;
        }
        const option = target.querySelector(`option[value="${target.value}"]`) as HTMLOptionElement;
        if (option && option.dataset.description) {
            console.log(option, target.dataset.description);
            element.innerText = option.dataset.description;
        } else {
            element.innerText = '';
        }
        target.addEventListener('change', () => {
            const option = target.querySelector(`option[value="${target.value}"]`) as HTMLOptionElement;
            if (option && option.dataset.description) {
                console.log(option, target.dataset.description);
                element.innerText = option.dataset.description;
            } else {
                element.innerText = '';
            }
        });
    });

    // Confirm buttons
    (document.querySelectorAll('.confirmBtn') as NodeListOf<HTMLAnchorElement | HTMLButtonElement>).forEach(elem => {
        const content = elem.dataset.confirm ?? elem.getAttribute('title');
        elem.addEventListener('click', e => {
            if (!confirm(content)) {
                e.preventDefault();
            }
        });
    });

    // Tooltips
    initTooltips(document);

    // Toggles
    document.querySelectorAll('[data-toggle="submit"]').forEach(element => {
        element.addEventListener("change", () => {
            // @ts-ignore
            element.findParentElement("form").submit();
        });
    });
    (document.querySelectorAll('[data-toggle="shuffle"]') as NodeListOf<HTMLElement>).forEach(element => {
        if (element.title) {
            new bootstrap.Tooltip(element);
        }
        if (!element.dataset.target) {
            // Missing target
            return;
        }
        const targets = document.querySelectorAll(element.dataset.target) as NodeListOf<HTMLSelectElement>;
        if (targets.length === 0) {
            // Invalid target
            return;
        }
        element.addEventListener("click", () => {
            targets.forEach(target => {
                const options = target.querySelectorAll('option');
                if (options.length === 0) {
                    return;
                }
                const index = Math.floor(Math.random() * options.length);
                target.value = options[index].value;
                const e = new Event("change", {bubbles: true});
                target.dispatchEvent(e);
            });
        });
    });

    // Datepickers
    initDatePickers();

    // Pages
    route(request);

    // Pagination
    const limitSelect = document.getElementById('page-limit') as HTMLSelectElement | null;
    if (limitSelect) {
        limitSelect.addEventListener('change', () => {
            window.location.href = window.location.origin + window.location.pathname + '?l=' + parseInt(limitSelect.value);
        });
    }

    // Filter clear
    const clearFilterBtn = document.getElementById('clear-filter') as HTMLButtonElement | null;
    if (clearFilterBtn) {
        // @ts-ignore
        const form = clearFilterBtn.findParentElement('form') as HTMLFormElement;
        if (form) {
            const inputs = form.querySelectorAll('input[type="text"],input[type="search"],input[type="number"]') as NodeListOf<HTMLInputElement>;
            clearFilterBtn.addEventListener('click', () => {
                inputs.forEach(input => {
                    input.value = '';
                });
                form.submit();
            });
        }
    }

    // Table sorting
    const entityTable = document.querySelector('.entity-table') as HTMLTableElement | null;
    if (entityTable) {
        // @ts-ignore
        const form = clearFilterBtn.findParentElement('form') as HTMLFormElement;
        if (form) {
            const sortInput = form.querySelector('input[name="s"]') as HTMLInputElement;
            const sortDirectionInput = form.querySelector('input[name="sd"]') as HTMLInputElement;

            const sortables = form.querySelectorAll('th[data-name][data-sortable]') as NodeListOf<HTMLTableHeaderCellElement>;
            sortables.forEach(sortable => {
                const name = sortable.dataset.name;
                sortable.addEventListener('click', () => {
                    if (sortInput.value === name) {
                        sortDirectionInput.value = sortDirectionInput.value === 'asc' ? 'desc' : 'asc';
                    } else {
                        sortInput.value = name;
                        sortDirectionInput.value = 'asc';
                    }
                    form.submit();
                });
            })
        }
    }
});